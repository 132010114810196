/*
 * Report errors and info to rollbar API.
 */
import { useCallback, useMemo } from 'react'
import uncaught from 'uncaught'
import Rollbar from 'rollbar'
import { useAuth, fetchUser } from './connectors/authContext'
import { DEV, INTEGRATION } from './appStages'

const environment = process.env.GATSBY_STAGE

const enabled =
  environment != null && environment !== DEV && environment !== INTEGRATION

// For now, regular function copy for the case where we call
// it inside apollo client.js (no react context to provide auth0 info).
export const reportError = async (error) => {
  const viewer = await fetchUser()

  const payload = {
    url: window.location.href,
    person: viewer || { id: '(No person identified)' },
  }

  if (!enabled) {
    console.log('Rollbar reportError disabled')

    throw new Error(error)
  }

  rollbar.configure({ payload }).error(error)
}

/*
 * Data for rollbar.
 */
const useGetInfo = () => {
  const { viewer } = useAuth()

  const url = window.location.href

  return useMemo(() => {
    const person = viewer || { id: '(No person identified)' }

    return { person, url }
  }, [url, viewer])
}

const rollbar = new Rollbar({
  enabled,
  accessToken: process.env.GATSBY_ROLLBAR_TOKEN_APP,
  environment,
})

export const useReportError = () => {
  const payload = useGetInfo()

  return useCallback(
    (error) => {
      if (!enabled) {
        console.log('Rollbar reportError disabled')
      }

      rollbar.configure({ payload }).error(error)
    },
    [payload]
  )
}

export const useReportLogin = () => {
  const payload = useGetInfo()

  return useCallback(
    (info) => {
      if (!enabled) {
        console.log('Rollbar reportLogin disabled')
      }

      rollbar.configure({ payload }).info('User login')
    },
    [payload]
  )
}

/*
 * NOTE: This doesn't report anything useful in rollbar when
 * error is ErrorEvent and error.error is null!
 * This happened in the case of the ResizeObserver limit error.
 */
export const useStartUncaughtReporting = () => {
  const reportError = useReportError()

  uncaught.start()
  // Note how uncaught only extracts event.error which is null in the
  // ResizeObserver error case.
  uncaught.addListener((error) => {
    if (error === null) {
      // quick fix: ignore
      // Longer fix: improve useMeasure
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      return
    }

    reportError(error)
  })
}
