import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import { Magic } from 'magic-sdk'
import useUser, { fetchUser as rawFetchUser } from './useUser'
import { useApolloClient } from '@apollo/client'
import parseCsrfCookie from './parseCsrfCookie'
import useInterval from 'react-use/lib/useInterval'

import config from '../../config.json'

const { SAVE_DELAY } = config

let _apolloClient

function useBeforeLogout() {
  const beforeLogoutFns = useRef([])

  const add = useCallback((fn) => {
    beforeLogoutFns.current.push(fn)

    return () => {
      const index = beforeLogoutFns.current.indexOf(fn)
      beforeLogoutFns.current.splice(index, 1)
    }
  }, [])

  const call = useCallback(async () => {
    return await Promise.all(beforeLogoutFns.current.map((fn) => fn()))
  }, [])

  return { add, call }
}

export const AuthContext = createContext()

export function useAuth() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a Auth0Provider')
  }
  return context
}

export function AuthProvider({ children }) {
  const { add: onBeforeLogout, call: callBeforeLogoutFns } = useBeforeLogout()
  const {
    user,
    renameUser,
    loading: userLoading,
    error,
    login: backendLogin,
    logout,
  } = useUser()

  const [clientLoading, setClientLoading] = useState(!_apolloClient)

  const loading = clientLoading || userLoading

  const client = useApolloClient()

  useEffect(() => {
    if (!client) {
      setClientLoading(true)
      return
    }

    if (_apolloClient !== client) {
      _apolloClient = client
      setClientLoading(false)
    }
  }, [client])

  const login = useCallback(
    async (email) => {
      try {
        const magic = new Magic(process.env.GATSBY_MAGIC_PUBLIC_KEY)
        const didToken = await magic.auth.loginWithMagicLink({
          email: email,
        })
        // returns the user
        await backendLogin(didToken)
      } catch (error) {
        console.error('An unexpected error occurred:', error)
      }
    },
    [backendLogin]
  )

  // The reason we are frequently testing this is because of the situation when the expiry date
  // occurs while the computer is sleeping. When waking it up again, we'll have to log out before
  // an auto-save is triggered.
  useInterval(
    async () => {
      const { sessionExpiresAt } = parseCsrfCookie()
      // When logged out, there is no sessionExpiresAt anymore.
      if (sessionExpiresAt && Date.now() + 20000 > sessionExpiresAt) {
        // We might already be logged out, so no callBeforeLogoutFns here.
        await logout()
      }
    },
    // Run this if a user is logged in.
    Boolean(user) ? SAVE_DELAY : null
  )

  const logoutWithBeforeFns = useCallback(async () => {
    try {
      await callBeforeLogoutFns()
    } finally {
      logout({ returnTo: window.location.origin })
    }
  }, [callBeforeLogoutFns, logout])

  const value = useMemo(
    () => ({
      viewer: user,
      renameUser,
      isAuthenticated: user != null && !userLoading,
      loading,
      error,
      login,
      logout: logoutWithBeforeFns,
      onBeforeLogout,
    }),
    [
      error,
      loading,
      login,
      logoutWithBeforeFns,
      onBeforeLogout,
      renameUser,
      user,
      userLoading,
    ]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export async function fetchUser() {
  return rawFetchUser(_apolloClient)
}

export async function getAuthHeaders() {
  return {
    // This is the csrf protection in the double-cookie pattern
    'X-Csrf-Token': parseCsrfCookie().crumb,
  }
}

/*
 * Special treatment for the fetch-json.ts code:
 * These are the options that a `fetch` to our backend needs.
 */
export async function getFetchOptions() {
  const headers = await getAuthHeaders()
  // credentials allows sending cookies
  return { headers, credentials: 'include' }
}
