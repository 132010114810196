/*
 * Note this hook cannot be used in multiple consumers (local state)
 */
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { gql, useApolloClient, useQuery, useMutation } from '@apollo/client'

import config from '../../config.json'

const { SAVE_DELAY } = config

const GET_USER = gql`
  query getUser {
    user {
      id
      name
      email
    }
  }
`

const LOGIN = gql`
  mutation Login($token: String!) {
    login(token: $token) {
      id
      name
      email
    }
  }
`

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`

const SET_USERNAME = gql`
  mutation setUsername($name: String!) {
    setUsername(name: $name)
  }
`

export default function useUser() {
  // When someone changes a username, we do an optimistic update here but debounce the server call.
  const [user, setUser] = useState()
  const client = useApolloClient()

  const { data = {}, error, loading: getLoading } = useQuery(GET_USER)

  const { user: dbUser } = data

  const [_login] = useMutation(LOGIN)
  const [_logout, { loading: logoutLoading }] = useMutation(LOGOUT)
  const [_saveUsername] = useMutation(SET_USERNAME)

  const login = useCallback(
    async (token) => {
      await _login({ variables: { token } })
      // If _login() doesn't re-mount this hook, need to check user when it's complete.
      await client.resetStore()
    },
    [_login, client]
  )

  const logout = useCallback(async () => {
    await _logout()
    await client.resetStore()
  }, [_logout, client])

  useEffect(() => {
    setUser(dbUser)
  }, [dbUser])

  const _debouncedSaveUsername = useDebouncedCallback(async (name) => {
    if (name === '' || name === dbUser?.name) {
      return
    }

    await _saveUsername({ variables: { name } })
    await client.resetStore()
  }, SAVE_DELAY)

  const renameUser = useCallback(
    (name) => {
      // Optimistic update.
      setUser((user) => ({ ...user, name }))
      // Debounced database call
      _debouncedSaveUsername(name)
    },
    [_debouncedSaveUsername]
  )

  return {
    user,
    renameUser,
    loading: getLoading || logoutLoading,
    error,
    login,
    logout,
  }
}

export async function fetchUser(client) {
  const result = await client.query({ query: GET_USER })

  return result.data.user
}
