import fetch from 'isomorphic-fetch'
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  // concat,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/link-error'
import { getAuthHeaders } from '../connectors/authContext'
// import { WebSocketLink } from 'apollo-link-ws'
import { reportError } from '../reporter'

const cache = new InMemoryCache({
  typePolicies: {
    // for some queries, no need to reach out to back-end if we already have it in cache.
    Query: {
      fields: {
        release(existingDataFromCache, { args, toReference }) {
          return (
            // idea: query server if both undefined
            existingDataFromCache ||
            toReference({
              __typename: 'Release',
              id: args?.id,
            })
          )
        },
        history(existingDataFromCache, { args, toReference }) {
          return (
            existingDataFromCache ||
            toReference({
              __typename: 'History',
              id: args?.id,
            })
          )
        },
        wip(existingDataFromCache, { args, toReference }) {
          return (
            existingDataFromCache ||
            toReference({
              __typename: 'Wip',
              id: args?.id,
            })
          )
        },
      },
    },
  },
})

const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const error = graphQLErrors
      .map(({ message, locations, path }) => {
        return `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      })
      .join('\n')

    throw new Error(error)
  }

  if (networkError) {
    reportError(new Error(`[ApolloLink: Network error]: ${networkError}`))
  }
})

const authMiddleware = setContext(async (_, { headers }) => {
  const authHeaders = await getAuthHeaders()

  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  }
})

const httpLink = new HttpLink({
  uri: process.env.GATSBY_API_URL,
  fetch,
  // This allows sending cookies.
  credentials: 'include',
})

// Batch? https://www.apollographql.com/docs/link/links/batch-http.html
const client = new ApolloClient({
  // link: list of links to use.
  link: ApolloLink.from([errorMiddleware, authMiddleware, httpLink]),
  cache,
})

export default client
