export default function parseCsrfCookie() {
  if (document.cookie === '') {
    return {}
  }

  const entry = document.cookie
    .split(';')
    .map((v) => v.split('='))
    .find((v) => {
      return decodeURIComponent(v[0].trim()) === 'csrf'
    })

  if (!entry) {
    return {}
  }

  return JSON.parse(decodeURIComponent(entry[1].trim()))
}
